import axios from "axios";
import PerformRecordValidation from "./PerformRecordValidation";
import AxiosErrorHandler from "./AxiosErrorHandler";
import APIGetAuthHeader from "./APIGetAuthHeader";

const APIDeleteRecord = async (options) => {

    let axiosConfig = APIGetAuthHeader();
     
    axios.delete((options.server ? options.server : process.env.REACT_APP_API_BASE)  + options.url, axiosConfig)
    .then(res => PerformRecordValidation(res.data,options))
    .catch(err => {
        if (options.PostErrorFunction){
            if (options.showErrorBeforeFunction !== undefined && options.showErrorBeforeFunction === true )
                AxiosErrorHandler(err,options);
            return options.PostErrorFunction(err, options);
         }
        else
            AxiosErrorHandler(err,options)
      });
}
export default APIDeleteRecord;