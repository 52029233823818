// assets
import { IconSettings } from '@tabler/icons';
import { IconMessages } from '@tabler/icons';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import AppsIcon from '@mui/icons-material/Apps';
import DataArrayIcon from '@mui/icons-material/DataArray';
import RememberMeIcon from '@mui/icons-material/RememberMe';

// constant
const icons = {
    IconSettings,
    IconMessages,
    ImportContactsIcon,
    AppsIcon,
    DataArrayIcon,
    RememberMeIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    
    
};

export default other;
