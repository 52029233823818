import { useRoutes } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
// routes
import {AdminRoutes} from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
// ==============================|| ROUTING RENDER ||============================== //


export default function ThemeRoutes() {

    const { user } = useAuth();
  return useRoutes([AdminRoutes, LoginRoutes, AuthenticationRoutes]);
}
