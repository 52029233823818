// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from '../../utils/axios';
import { dispatch } from '../index';

const initialState = {
    resorts : [],   
    push_request:[],
    property_code:[],
    trigger_category:[],
    reservation:[],
    loading : false,
    filter:false
};

const slice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        resetFilters(state,action){
            state.resorts = [];
            state.property_code = [];
            state.push_request = [];
            state.trigger_category=[];
            state.reservation=[];
            state.loading = false;
            state.filter = false;
        },
        setResorts(state,action){
            state.resorts = action.payload;
        },
        setReservation(state,action){
            state.reservation = action.payload;
        },
        setPushRequest(state,action){
            state.push_request = action.payload;
        },
        setProperyCode(state,action){
            state.property_code = action.payload;
        },
        setAddResortFilter(state,action){
           state.filter = true;
           state.resorts = []; //this is temp until i figure out how to filter by multiple
           state.resorts.push(action.payload) ; 
        },
        setAddReservationFilter(state,action){
            state.filter = true;
            state.reservation = []; //this is temp until i figure out how to filter by multiple
            state.reservation.push(action.payload) ; 
         },
        setAddPushRequestFilter(state,action){
            state.filter = true;
            state.push_request = [];//this is temp until i figure out how to filter by multiple
            state.push_request.push(action.payload) ; 
         },
         setAddPropertyCodeFilter(state,action){
            state.filter = true;
            state.property_code = [];//this is temp until i figure out how to filter by multiple
            state.property_code.push(action.payload) ; 
         },
        setRemoveResortFilter(state,action){             
            state.resorts =  state.resorts.filter(resort => (resort) !== action.payload) ;   
            state.filter = (state.resorts && state.resorts.length > 0);             
         },
         setRemoveReservationtFilter(state,action){             
            state.reservation =  state.reservation.filter(reserv => (reserv) !== action.payload) ;   
            state.filter = (state.reservation && state.reservation.length > 0);             
         },
         setRemovePushRequestFilter(state,action){             
            state.push_request =  state.push_request.filter(push_request => (push_request) !== action.payload) ;   
            state.filter = (state.push_request && state.push_request.length > 0);             
         },
         setRemovePropertyCodeFilter(state,action){             
            state.property_code =  state.property_code.filter(property_code => (property_code) !== action.payload) ;   
            state.filter = (state.property_code && state.property_code.length > 0);             
         },
        


     }
}
);
export default slice.reducer;

export function AddResortToFilter(data){
    return async () => { try { dispatch(slice.actions.setAddResortFilter(data));  }catch(e){} };
}

export function AddReservationToFilter(data){
    return async () => { try { dispatch(slice.actions.setAddReservationFilter(data));  }catch(e){} };
}

export function AddPushRequestToFilter(data){
    return async () => { try { dispatch(slice.actions.setAddPushRequestFilter(data)); } catch(e){console.log(e)} };
}

export function AddPropertyCodeToFilter(data){
    return async () => { try { dispatch(slice.actions.setAddPropertyCodeFilter(data)); } catch(e){console.log(e)} };
}

export function RemoveResortFromFilter(data){
    return async () => {  try { dispatch(slice.actions.setRemoveResortFilter(data));   } catch(e){console.log(e)} };
}

export function RemoveReservationFromFilter(data){
    return async () => {  try { dispatch(slice.actions.setRemoveReservationtFilter(data));   } catch(e){console.log(e)} };
}

export function RemovePushRequestFromFilter(data){
    return async () => {  try {  dispatch(slice.actions.setRemovePushRequestFilter(data));  } catch(e){}  };
}

export function RemovePropertyCodeFromFilter(data){
    return async () => {  try {  dispatch(slice.actions.setRemovePropertyCodeFilter(data));  } catch(e){}  };
}

export function ClearResortFilter(){
    return async () => {  try {  dispatch(slice.actions.resetFilters());   }  catch(e){}  };
}

export function ClearPushRequestFilter(){
    return async () => {
        try {
            dispatch(slice.actions.resetFilters());   
        }
        catch(e){}
    };
}