// assets
import { IconSettings } from '@tabler/icons';
import { IconMessages } from '@tabler/icons';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import AppsIcon from '@mui/icons-material/Apps';
import DataArrayIcon from '@mui/icons-material/DataArray';
import RememberMeIcon from '@mui/icons-material/RememberMe';

// constant
const icons = {
    IconSettings,
    IconMessages,
    ImportContactsIcon,
    AppsIcon,
    DataArrayIcon,
    RememberMeIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const agentMenuItems = {
    id: 'settings-parent',
    title: 'Agent Menu',
    type: 'group',
    children: [
        {
            id: 'conversation-parent',
            title: 'Conversations',
            type: 'collapse',
            breadcrumbs: true,
            icon: icons.IconMessages,
            iconColor: '#000080',
            children: [
                {
                    id: 'conversation',
                    title: 'Inbox',
                    
                    type: 'item',
                    url: '/chat',
                    breadcrumbs: false
                },
                {
                    id: 'closedconversation',
                    title: 'Closed',
                    
                    type: 'item',
                    url: '/closed-chats',
                    breadcrumbs: false
                }]
            },

        {
            id: 'application-parent',
            title: 'Application',
            type: 'collapse',
            icon: icons.AppsIcon,
            iconColor: '#000080',
            breadcrumbs: false,
            children: [
                {
                    id: 'agents',
                    title: 'Agents',
                    type: 'item',
                    url: '/agents',
                    breadcrumbs: false,
                },
               
                {
                    id: 'businessnumbers',
                    title: 'Business Numbers',
                    type: 'item',
                    url: '/business-numbers',
                    breadcrumbs: false,
                },
                
                {
                    id: 'agentgroups',
                    title: 'Agent Groups',
                    type: 'item',
                    url: '/agent-groups',
                    breadcrumbs: false,
                },
                
            ]
        },
        {
            id: 'data-parent',
            title: 'Data',
            type: 'collapse',
            icon: icons.DataArrayIcon,
            iconColor: '#252d64',
            breadcrumbs: false,
            children: [{
                id: 'mobileenduser',
                title: 'Mobile End Users',
                type: 'item',
                url: '/mobile-end-user',
                breadcrumbs: false,
                iconColor: '#000080'
            }]
        }
 
        
    ]
};

export default agentMenuItems;
