// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,

    Typography
} from '@mui/material';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router';
// assets
import SmsTwoToneIcon from '@mui/icons-material/SmsTwoTone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { ConvertDateTimeToAGO ,convertUTCDateToLocalDate} from 'utils/date/getLocaleDate';
// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({conversationChat,setOpen}) => {
    const theme = useTheme();
    const nav = useNavigate();

    return (

        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        ><div style={{minWidth:'360px'}}></div>
            {conversationChat.map((message)=> (<> 
            <ListItemWrapper>
                <ListItem alignItems="center" onClick={()=> { setOpen(false); nav(`chat/${message.id}`)}}>
                    <ListItemAvatar>
                   {message.conversation_type === 'whatsapp' ?  
                   <WhatsAppIcon sx={{color: "green" }} /> :
                   <SmsTwoToneIcon  sx={{color: "#32CFE8" }} />   }
                   
                    </ListItemAvatar>
                    <ListItemText primary={message.name} />
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    {ConvertDateTimeToAGO(convertUTCDateToLocalDate(DateTime.fromJSDate(new Date(message.time))))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container"  onClick={()=> { setOpen(false); nav(`chat/${message.id}`)}}>
                    <Grid item xs={12} sx={{ pb: 0 }}><div>
                        <Typography variant="subtitle2">{message.lastMsg}</Typography>
                        </div>
                    </Grid>
           
                </Grid>
            </ListItemWrapper>
            <Divider /></>))}
            
        </List>
    );
};

export default NotificationList;
